import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationIcon,
} from 'react-line-awesome';
import { Alert } from 'react-bootstrap';

import { Alert as IAlert, removeAlert } from 'infraestructure/store/actions/ui';

export interface AlertLayoutProps {
  alerts: IAlert[];
  removeAlert(id: string): void;
}

const mapIcons = {
  info: ExclamationIcon,
  warning: ExclamationCircleIcon,
  danger: ExclamationTriangleIcon,
};

interface IconProps {
  type: string;
}

const Icon = (props: IconProps): ReactElement => {
  const TypeIcon = mapIcons[props.type];

  return <TypeIcon />;
};

export interface TimeOutedAlertProps {
  alert: IAlert;
  remove(id: string): void;
}

const TimeOutedAlert = (props: TimeOutedAlertProps) => {
  const { alert } = props;

  React.useEffect(() => {
    const timeout = setTimeout(() => props.remove(alert.id), 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Alert
      key={alert.id}
      variant={alert.type}
      dismissible
      onClose={() => props.remove(alert.id)}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="px-1">
          <Icon type={alert.type} />
        </div>
        {alert.text}
      </div>
    </Alert>
  );
};

const AlertLayout = (props: AlertLayoutProps): ReactElement => {
  return (
    <div
      style={{
        zIndex: 3000,
        position: 'fixed',
        top: '1rem',
        right: '1rem',
      }}
    >
      {props.alerts.map((alert) => (
        <TimeOutedAlert
          key={alert.id}
          alert={alert}
          remove={() => props.removeAlert(alert.id)}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    alerts: state.ui.alerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeAlert: (id: string) => dispatch(removeAlert(id)),
  };
};

const AlertLayoutConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertLayout);

export default AlertLayoutConnected;
