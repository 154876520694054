import { combineReducers, createStore, Store, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from './reducers/auth';
import { uiReducer } from './reducers/ui';

const reducers = combineReducers({ auth: authReducer, ui: uiReducer });

export class AppStore {
  private store: Store;

  private static _instance = new AppStore();

  private constructor() {
    if (process.env.NODE_ENV === 'development') {
      this.store = createStore(reducers, composeWithDevTools());
    } else {
      this.store = createStore(reducers);
    }
  }

  public static get instance(): Store {
    return this._instance.store;
  }
}
