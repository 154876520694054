import { Country } from 'domain/constances/country';

export default [
  {
    country: Country.NI,
    id: 1,
    name: 'Antonio Miguel Sosa',
    company: 'MaxCell',
    avatar:
      'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200',
    testimony:
      'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.',
  },
  {
    country: Country.NI,
    id: 2,
    name: 'Maria Lucia Palomares',
    company: 'Rapi Comida',
    avatar: '',
    testimony:
      'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.',
  },
  {
    country: Country.NI,
    id: 3,
    name: 'Consolacion Torres',
    company: 'Tasty Food',
    avatar: '',
    testimony:
      'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.',
  },
  {
    country: Country.GT,
    id: 4,
    name: 'Consolacion Torres',
    company: 'Tasty Food',
    avatar: '',
    testimony:
      'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.',
  },
  {
    country: Country.GT,
    id: 2,
    name: 'Maria Lucia Palomares',
    company: 'Rapi Comida',
    avatar: '',
    testimony:
      'Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec rutrum congue leo eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.',
  },
];
