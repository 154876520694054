import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppStore } from './infraestructure/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick-theme.css';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={AppStore.instance}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
