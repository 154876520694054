import { PaymentDetail, PaymentMovement } from 'domain/models/Customer/Payment';
import { HttpRepository } from 'infraestructure/repositories/';

export interface PaymentDetailResponse {
  paymentconcept: PaymentDetail[];
}

export interface PaymentMovementResponse {
  movements: PaymentMovement[];
}

export class OperationRepository extends HttpRepository {
  endpoint = 'customers/operation';

  protected getService(): string {
    return process.env.REACT_APP_CUSTOMER_SERVICE || '';
  }

  async getPaymentDetail(operationId: number): Promise<PaymentDetail[]> {
    const response = await this.client.get(this.buildUrl('paymentconcept'), {
      operationId,
    });
    if (response === null) {
      return [];
    }
    const payload = response.data as PaymentDetailResponse;
    return payload.paymentconcept;
  }

  async getMovements(operationId: number): Promise<PaymentMovement[]> {
    const response = await this.client.get(this.buildUrl('movements'), {
      operationId,
    });
    if (response === null) {
      return [];
    }
    const payload = response.data as PaymentMovementResponse;
    return payload.movements;
  }
}
