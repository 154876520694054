import { Auth, AuthAction, USER_LOGIN, USER_LOGOUT } from '../actions/auth';
import { AuthRepository } from 'infraestructure/repositories';

const initialState: Auth = {
  isLogged: false,
};

export const authReducer = (
  state: Auth = initialState,
  action: AuthAction
): Auth => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, isLogged: true };
    case USER_LOGOUT:
      new AuthRepository().removeToken();
      return { ...state, isLogged: false };
    default:
      return state;
  }
};
