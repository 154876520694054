import React, { ReactElement } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

interface Factoring {
  company: string;
  names: string;
  roll: string;
  phone: string;
  email: string;
  message: string;
}

export const FactoringForm = (): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Factoring>();

  const regex = process.env.REACT_APP_PHONE_PATTER || '';
  const phonePattern = new RegExp(regex);

  const handleOnSubmit = (data) => {
    console.log(data);
  };

  const required = 'Este campo es requerido';
  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <Form.Group>
        <Form.Control
          {...register('names', { required: true })}
          isInvalid={errors?.names !== undefined}
          placeholder="Nombres"
        />
        <Form.Control.Feedback type="invalid">
          {errors.names ? required : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Control
          {...register('roll', { required: true })}
          isInvalid={errors?.roll !== undefined}
          placeholder="Cargo"
        />
        <Form.Control.Feedback type="invalid">
          {errors.roll ? required : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Control
          {...register('company', { required: true })}
          name="company"
          placeholder="Organización"
          isInvalid={errors?.company !== undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors.company?.type === 'required' ? required : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Control
          {...register('phone', { required: true, pattern: phonePattern })}
          name="phone"
          type="tel"
          placeholder="Teléfono"
          isInvalid={errors?.phone !== undefined}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone?.type === 'required' ? required : null}
          {errors.phone?.type === 'pattern'
            ? 'Numero de teléfono invalido'
            : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Control
          {...register('email', { required: true })}
          placeholder="Correo"
          isInvalid={errors?.email !== undefined}
          type="email"
        />
        <Form.Control.Feedback type="invalid">
          {errors.email ? required : null}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Control
          {...register('message', { required: true })}
          style={{ resize: 'none' }}
          as="textarea"
          rows={3}
          placeholder="Mensaje"
          isInvalid={errors?.message !== undefined}
          type="text"
        />
        <Form.Control.Feedback type="invalid">
          {errors.message ? required : null}
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="submit" size="lg" variant="danger">
        Enviar
      </Button>
    </Form>
  );
};

export default FactoringForm;
