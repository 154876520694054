import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

export interface CircularIndicatorProps {
  text: string;
}

export const CircularIndicator = (
  props: CircularIndicatorProps
): ReactElement => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <div className="text-center">
        <Spinner animation="border" variant="danger" />
        <div>
          <h5>{props.text}</h5>
        </div>
      </div>
    </div>
  );
};
