import { AxiosRequestConfig } from 'axios';
import { AuthRepository } from 'infraestructure/repositories';

export const Authentication = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const repository = new AuthRepository();
  const token = repository.loadToken();

  config.headers['Strict-Transport-Security'] =
    'max-age=31536000 ; includeSubDomains';
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

export default Authentication;
