export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export interface Alert {
  id: string;
  text: string;
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
}

export interface UIAction {
  type: string;
  payload?: unknown;
}

export interface UI {
  alerts: Alert[];
}

export const showAlert = (text: string, type): UIAction => ({
  type: ADD_ALERT,
  payload: { text, type },
});
export const removeAlert = (id: string): UIAction => ({
  type: REMOVE_ALERT,
  payload: id,
});
