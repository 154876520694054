import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import contactUsIcon from 'assets/images/iconos/contact-us.svg';

import './index.css';
import MainModal, { ModalChildProps } from '../MainModal';
import CreditLineForm from '../CreditLineForm';

export interface PropsAskCredit {
  className?: string;
}

export const AskCredit = (props: PropsAskCredit): ReactElement => {
  const [show, setShow] = React.useState<boolean>(false);
  const site = process.env.REACT_APP_SITE_TITLE || '';

  return (
    <>
      <Button
        className={`btn-lg btn-block text-center ask-credit btn-danger ${props.className}`}
        onClick={() => setShow(true)}
      >
        <span>
          <img className="img-fluid p-2" src={contactUsIcon}></img>
        </span>
        <br></br>
        <h6 className="title-button">Solicita tu {site}</h6>
      </Button>
      <MainModal
        title="Datos personales"
        show={show}
        onHide={() => setShow(false)}
        render={(props: ModalChildProps) => (
          <CreditLineForm onHide={props.onHide} />
        )}
      />
    </>
  );
};
