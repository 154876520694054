import { Routes } from '../../routes';
import { Route, Switch, Redirect } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { AppProps } from 'App';

const HomePage = React.lazy(() => import('presentation/pages/home'));
const AboutUsPage = React.lazy(() => import('presentation/pages/AboutUs'));
const ProductPage = React.lazy(() => import('presentation/pages/Product'));
const AffiliatedPage = React.lazy(
  () => import('presentation/pages/Affiliated')
);
const AffiliatedDetailPage = React.lazy(
  () => import('../../pages/AffiliatedDetail')
);
const PaymentPointPage = React.lazy(() => import('../../pages/PaymentPoint'));
const CustomerServicePage = React.lazy(
  () => import('../../pages/CustomerService')
);
const ClaimProcessPage = React.lazy(() => import('../../pages/Claimprocess'));

const DashboardPage = React.lazy(() => import('../../pages/Dashboard'));

const CreditCalculatorPage = React.lazy(
  () => import('../../pages/CreditCalculator')
);
const CalculateInterestPage = React.lazy(
  () => import('../../pages/calculateInterest')
);

const ContractsPage = React.lazy(() => import('../../pages/contracts'));

const FinancialEducationPage = React.lazy(
  () => import('../../pages/financialEducation')
);

const DownloadAppPage = React.lazy(() => import('../../pages/DownloadApp'));

const SalesPage = React.lazy(() => import('../../pages/Sales'));

const NewsPage = React.lazy(() => import('../../pages/News'));

const FactoringDasboardPage = React.lazy(
  () => import('../../pages/FactoringDashboard')
);

const WizzardFactoringPage = React.lazy(
  () => import('../../pages/FactoringDashboard/WizardFactoring')
);

const PasswordRecoveryPage = React.lazy(
  () => import('../../pages/passwordRecovery')
);

const FrequentlyQuestionsPage = React.lazy(() => import('../../pages/Faqs'));

const SelectorBankGt = React.lazy(
  () => import('presentation/pages/PaymentPoint/SelectorBankGt')
);

const ResetPassPage = React.lazy(() => import('presentation/pages/ResetPass'));

const PromotionHistoryPage = React.lazy(
  () => import('presentation/pages/PromotionHistory')
);

const FactoringPage = React.lazy(
  () => import('presentation/pages/FactoringPage')
);

const TermsAndConditionsPage = React.lazy(
  () => import('presentation/pages/TermsAndConditions')
);

const InversionsPage = React.lazy(
  () => import('presentation/pages/Inversions')
);

const InstantCashPage = React.lazy(
  () => import('presentation/pages/instantCash')
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RoutesComponent = (props: AppProps): ReactElement => {
  return (
    <Switch>
      <Route path={Routes.root} exact component={HomePage} />
      <Route path={Routes.aboutus} component={AboutUsPage} exact />
      <Route
        path={`${Routes.products}/:pruductType/:name`}
        exact
        component={ProductPage}
      />
      <Route path={Routes.affiliated} exact component={AffiliatedPage} />
      <Route
        path={`${Routes.affiliatedDetail}/:companyId`}
        exact
        component={AffiliatedDetailPage}
      />
      <Route path={Routes.paymentPoints} exact component={PaymentPointPage} />
      <Route
        path={Routes.customerService}
        exact
        component={CustomerServicePage}
      />
      <Route
        path={Routes.creditCalculator}
        exact
        component={CreditCalculatorPage}
      />
      <Route path={Routes.claimProcess} exact component={ClaimProcessPage} />
      <Route
        path={Routes.CalculateInterest}
        exact
        component={CalculateInterestPage}
      />
      <Route path={Routes.Contracts} component={ContractsPage} exact />
      <Route
        path={Routes.financialeducation}
        exact
        component={FinancialEducationPage}
      />
      <Route path={Routes.downloadApp} exact component={DownloadAppPage} />
      <Route path={`${Routes.News}/:id`} exact component={NewsPage} />
      <Route
        path={Routes.passwordRecovery}
        component={PasswordRecoveryPage}
        exact
      />
      <Route path={Routes.Sales} component={SalesPage} exact />

      <Route
        path={Routes.frecuentlyquestions}
        exact
        component={FrequentlyQuestionsPage}
      />
      <Route path={Routes.resetPass} component={ResetPassPage} exact />
      <Route path={Routes.paymentPointsGt} component={SelectorBankGt} />
      <Route path={Routes.promotionHistory} component={PromotionHistoryPage} />
      <Route path={Routes.resetPass} component={ResetPassPage} />
      <Route path={Routes.factoring} component={FactoringPage} />
      <Route
        path={Routes.termsAndConditions}
        component={TermsAndConditionsPage}
      />
      <Route path={Routes.inversiones} component={InversionsPage} />
      <Route path={Routes.instantCash} component={InstantCashPage} />
      {props.isLogged ? (
        <>
          <Route path={Routes.dashboard} component={DashboardPage} exact />
          <Route
            path={Routes.factoringDasboard}
            component={FactoringDasboardPage}
            exact
          />
          <Route
            exact
            path={Routes.wizzardFactoring}
            component={WizzardFactoringPage}
          />
        </>
      ) : null}
      <Redirect to={Routes.root} />
    </Switch>
  );
};
export default RoutesComponent;
