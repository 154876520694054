import React, { ReactElement } from 'react';
import 'App.css';
import { HelmetProvider } from 'react-helmet-async';
import { NavbarLayout } from 'presentation/layouts';
import AuthConext from 'presentation/Context/Auth';
import { login, logout } from 'infraestructure/store/actions/auth';
import { connect } from 'react-redux';
import AlertLayout from './presentation/layouts/AlterLayout';
import { Col, Container, Row } from 'react-bootstrap';
import 'aos/dist/aos.css';
import ReactGA from 'react-ga';
import ScrollTop from '../src/presentation/components/scrollTop';
import ScrollToTop from 'react-scroll-to-top';
import { AngleUpIcon } from 'react-line-awesome';
import RouterComponent from '../src/presentation/components/RouterComponent';
import { AuthRepository } from 'infraestructure/repositories';
import LoadingComponent from 'presentation/components/LoadingComponent';
import ReactPixel from 'react-facebook-pixel';
import 'react-datepicker/dist/react-datepicker.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import whatsappAvatarCredex from '../src/assets/images/iconos/avatarCredex.jpg';
import whatsappAvatarZu from '../src/assets/images/iconos/avatarZu.jpg';

const GAId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';
const FacebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';
const whatsappNumber = process.env.REACT_APP_WHATSAPP || '';
const site = process.env.REACT_APP_SITE_TITLE || '';
const advancedMatching = {
  em: '',
  fn: '',
  ln: '',
  ct: '',
  country: '',
  db: '',
  ge: '',
  ph: '',
  st: '',
  zp: '',
};
const FPOptions = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(FacebookPixelId, advancedMatching, FPOptions);
ReactPixel.pageView();

ReactGA.initialize(GAId);
ReactGA.pageview(window.location.pathname + window.location.search);

export interface AppProps {
  isLogged: boolean;
  login(): void;
  logout(): void;
}

function App(props: AppProps): ReactElement {
  const handleLoginChange = (isLogged: boolean) => {
    if (isLogged) {
      props.login();
    } else {
      props.logout();
    }
  };
  const repository = new AuthRepository();
  const context = { isLogged: props.isLogged, setIsLogged: handleLoginChange };

  React.useEffect(() => {
    const token = repository.loadToken();
    if (token !== null) {
      props.login();
    }
    const favicon = document.querySelector('#favicon');
    favicon?.setAttribute('href', process.env.REACT_APP_FAVICON || '');

    const descriptionSite = document.querySelector('meta[name="description"]');
    descriptionSite?.setAttribute(
      'content',
      process.env.REACT_APP_DESCRIPTION_SITE || ''
    );

    const keywordsSite = document.querySelector('meta[name="keywords"]');
    keywordsSite?.setAttribute('content', process.env.REACT_APP_KEYWORD || '');

    const copyrightSite = document.querySelector('meta[name="keywords"]');
    copyrightSite?.setAttribute(
      'content',
      process.env.REACT_APP_COPYRIGHT_SITE || ''
    );

    const ogTitleFacebook = document.querySelector('meta[name="og:title"]');
    ogTitleFacebook?.setAttribute(
      'content',
      process.env.REACT_APP_OG_TITLE || ''
    );

    const ogDescriptionFacebook = document.querySelector(
      'meta[name="og:description"]'
    );
    ogDescriptionFacebook?.setAttribute(
      'content',
      process.env.REACT_APP_DESCRIPTION_SITE || ''
    );

    const ogTitleTwitter = document.querySelector('meta[name="twitter:title"]');
    ogTitleTwitter?.setAttribute(
      'content',
      process.env.REACT_APP_OG_TITLE || ''
    );

    const ogDescriptionTwitter = document.querySelector(
      'meta[name="twitter:description"]'
    );
    ogDescriptionTwitter?.setAttribute(
      'content',
      process.env.REACT_APP_DESCRIPTION_SITE || ''
    );

    const ogUrl = document.querySelector('meta[name="og:url"]');
    ogUrl?.setAttribute('content', process.env.REACT_APP_WEB_URL || '');
  }, []);

  return (
    <AuthConext.Provider value={context}>
      <NavbarLayout>
        <HelmetProvider>
          <div className="App">
            <AlertLayout />
            <React.Suspense
              fallback={
                <Container className="spinner-show">
                  <Row>
                    <Col xs={12}>
                      <LoadingComponent textLoading="Cargando contenido del sitio..." />
                    </Col>
                  </Row>
                </Container>
              }
            >
              <FloatingWhatsApp
                phoneNumber={whatsappNumber}
                accountName={site}
                statusMessage="En línea"
                chatMessage={`Envíanos tu consulta \nPronto un asesor se comunicará contigo`}
                placeholder="Escribe tu mensaje"
                allowEsc
                avatar={
                  site === 'credex' ? whatsappAvatarCredex : whatsappAvatarZu
                }
              />
              <ScrollTop />
              <ScrollToTop
                smooth
                component={<AngleUpIcon />}
                color="white"
                className="scroll-to-top"
              />
              <RouterComponent {...props} />
            </React.Suspense>
          </div>
        </HelmetProvider>
      </NavbarLayout>
    </AuthConext.Provider>
  );
}
const mapStateToProps = (state) => {
  return {
    isLogged: state.auth.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch(login()),
    logout: () => dispatch(logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
