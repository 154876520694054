import { HttpResponse } from 'core/base/HttpClient';
import { Repository } from 'core/base/Repository';

export abstract class JsonRepository implements Repository {
  protected data: any = [];
  protected findBy = 'id';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async list(
    params: Record<string | number, string | number | boolean | null> = {}
  ): Promise<HttpResponse> {
    const filters = Object.keys(params);
    let items = [...this.data];
    filters.forEach((filter) => {
      items = items.filter((item: any) => item[filter] === params[filter]);
    });

    return {
      headers: {},
      data: items,
    };
  }

  async get(id: any): Promise<HttpResponse> {
    const result = this.data.find((item: any) => {
      const value = { [this.findBy]: undefined };
      Object.assign(value, item);
      return value[this.findBy] !== undefined && value[this.findBy] === id;
    });

    const response: HttpResponse = {
      data: result,
      headers: {},
    };

    return response;
  }

  async create(
    data: Record<string | number, string | number | boolean | null>
  ): Promise<HttpResponse> {
    this.data.push(data);
    return { data, headers: {} };
  }

  async update(
    id: number,
    data: Record<string | number, string | number | boolean | null>
  ): Promise<HttpResponse> {
    return {
      data: data,
      headers: {},
    };
  }
  async delete(id: number): Promise<HttpResponse> {
    return {
      data: true,
      headers: { statusCode: 204 },
    };
  }
}

export default JsonRepository;
