export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export interface Auth {
  isLogged: boolean;
}

export interface AuthAction {
  type: string;
}

export const logout = () => ({ type: USER_LOGOUT });
export const login = () => ({ type: USER_LOGIN });
