import React, { ReactChild, ReactChildren, ReactElement } from 'react';
import { NavBar } from 'presentation/components';
import { MenuItemList, AuthenticatedUserMenuItems } from './menu-items';
import { Footer } from 'presentation/components/footer';
import AuthContext, { AuthenticationContext } from 'presentation/Context/Auth';

export interface NavbarLayoutProps {
  children: ReactElement | ReactChild | ReactChildren;
}

export const NavbarLayout = (props: NavbarLayoutProps): ReactElement => {
  const context = React.useContext<AuthenticationContext>(AuthContext);

  let menuItems = MenuItemList;

  if (context.isLogged) {
    menuItems = [...menuItems, ...AuthenticatedUserMenuItems];
  }

  return (
    <>
      <NavBar items={menuItems}></NavBar>
      {props.children}
      <Footer items={menuItems}></Footer>
    </>
  );
};

export default NavbarLayout;
