import {
  faBuilding,
  faPhone,
  faUser,
  faUserTie,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyRepository } from 'infraestructure/repositories/CompanyRepository';
import { ModalChildProps } from 'presentation/components/MainModal';
import React, { ReactElement } from 'react';
import { Button, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './index.css';

interface AffiliateBussinesFormData {
  name: string;
  role: string;
  company: string;
  phoneNumber: string;
  email: string;
  message: string;
  country: string | null;
}

export const AffiliateBussinesForm = (props: ModalChildProps): ReactElement => {
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AffiliateBussinesFormData>();
  const required = 'Este campo es requerido';
  const regex = process.env.REACT_APP_PHONE_PATTER || '';
  const country = process.env.REACT_APP_SITE_ID || null;

  const phonePattern = new RegExp(regex);

  const handleOnSubmit = async (data: AffiliateBussinesFormData) => {
    const repository = new CompanyRepository();

    setIsSending(true);
    data.country = country;
    const response = await repository.affiliate(data);
    setIsSending(false);

    if (!response) {
      return;
    }
    const notifier = withReactContent(Swal);
    const { onHide } = props;
    await notifier.fire({
      text: 'Los datos han sido enviados',
      title: 'Enhorabuena',
      icon: 'success',
      showConfirmButton: true,
      timer: 4000,
    });
    onHide();
  };

  return (
    <div className="px-4">
      <h5>Datos de contacto</h5>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Row>
          <Col md={6} xs={12}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="bg-light border-0">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-danger"
                    ></FontAwesomeIcon>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  {...register('name', { required: true })}
                  isInvalid={errors?.name !== undefined}
                  placeholder="Nombres"
                  className="bg-light border-0"
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.name ? required : null}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="bg-light border-0">
                    <FontAwesomeIcon
                      icon={faUserTie}
                      className="text-danger"
                    ></FontAwesomeIcon>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  {...register('role', { required: true })}
                  isInvalid={errors?.role !== undefined}
                  placeholder="Cargo"
                  className="bg-light border-0"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.role ? required : null}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="bg-light border-0">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="text-danger"
                    ></FontAwesomeIcon>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  {...register('company', { required: true })}
                  placeholder="Organizacion"
                  isInvalid={errors?.company !== undefined}
                  className="bg-light border-0"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.company ? required : null}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="bg-light border-0">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="text-danger"
                    ></FontAwesomeIcon>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  {...register('phoneNumber', {
                    required: true,
                    pattern: phonePattern,
                  })}
                  type="tel"
                  placeholder="Teléfono"
                  isInvalid={errors?.phoneNumber !== undefined}
                  className="bg-light border-0"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber?.type === 'required' ? required : null}
                  {errors.phoneNumber?.type === 'pattern'
                    ? 'Numero de teléfono invalido'
                    : null}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="bg-light border-0">
                    <FontAwesomeIcon
                      icon={faEnvelopeOpenText}
                      className="text-danger"
                    ></FontAwesomeIcon>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  {...register('email', { required: true })}
                  placeholder="Correo"
                  isInvalid={errors?.email !== undefined}
                  type="email"
                  className="bg-light border-0"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email ? required : null}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Control
                {...register('message', { required: true })}
                as="textarea"
                rows={3}
                placeholder="Mensaje"
                className="affiliate-bussines-text bg-light border-0"
                isInvalid={errors?.message !== undefined}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message ? required : null}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={{ span: 2, offset: 10 }}>
            <Button
              type="submit"
              variant="danger"
              disabled={isSending}
              className="btn-block title-bryant d-flex justify-content-center"
            >
              <span>
                {isSending ? (
                  <Spinner
                    as="span"
                    size="sm"
                    animation="border"
                    className="mr-4"
                  />
                ) : null}
              </span>
              <span>Enviar</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AffiliateBussinesForm;
