import React, { ReactElement } from 'react';
import {
  faEnvelope,
  faHandHoldingUsd,
  faIdCard,
  faPhone,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalChildProps } from 'presentation/components';
import { Button, Form, Col, Row, InputGroup, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CreditRepository } from 'infraestructure/repositories';

interface CreditLineFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  identification: string;
  amount?: number;
  term?: number;
  country: string | null;
}

interface CreditLineFormProps extends ModalChildProps {
  term?: number;
  amount?: number;
}

export const CreditLineForm = (props: CreditLineFormProps): ReactElement => {
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreditLineFormData>();
  const required = 'Este campo es requerido';

  const regex = process.env.REACT_APP_PHONE_PATTER || '';
  const phonePattern = new RegExp(regex);
  const site = process.env.REACT_APP_SITE_TITLE || '';
  const docIdRegex = process.env.REACT_APP_DOCUMENT_ID_PATTER || '';
  const documentIdPattern = new RegExp(docIdRegex);

  const country = process.env.REACT_APP_SITE_ID || null;

  const handleOnSubmit = async (data: CreditLineFormData) => {
    const { onHide } = props;
    const repository = new CreditRepository();
    setIsSending(true);

    data.country = country;
    const response = await repository.requestCredit(data);
    setIsSending(false);
    if (!response) {
      return;
    }

    const notifier = withReactContent(Swal);
    await notifier.fire({
      text: 'Los datos han sido enviados',
      title: '¡Envío exitoso!',
      icon: 'success',
      showConfirmButton: true,
      timer: 4000,
    });
    onHide();
  };

  return (
    <div className="px-4">
      <div>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-light border-0">
                      <FontAwesomeIcon
                        icon={faUserAlt}
                        className="text-danger"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('firstName', { required: true })}
                    isInvalid={errors?.firstName !== undefined}
                    placeholder="Nombres"
                    className="bg-light border-0"
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.firstName ? required : null}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="text-danger bg-light border-0">
                      <FontAwesomeIcon
                        icon={faUserAlt}
                        className="text-danger"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('lastName', { required: true })}
                    isInvalid={errors?.lastName !== undefined}
                    placeholder="Apellidos"
                    className="bg-light border-0"
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.lastName ? required : null}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={7} xs={12}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-light border-0">
                      <FontAwesomeIcon
                        icon={faIdCard}
                        className="text-danger"
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('identification', {
                      required: true,
                      pattern: documentIdPattern,
                    })}
                    placeholder="Identificacion"
                    isInvalid={errors?.identification !== undefined}
                    className="bg-light border-0"
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.identification?.type === 'required' ? required : null}
                  {errors.identification?.type === 'pattern'
                    ? 'Numero de identificacion invalido'
                    : null}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={5} xs={12}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-light border-0">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="text-danger"
                      ></FontAwesomeIcon>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('phoneNumber', {
                      required: true,
                      pattern: phonePattern,
                    })}
                    type="tel"
                    placeholder="Teléfono"
                    isInvalid={errors?.phoneNumber !== undefined}
                    className="bg-light border-0"
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber?.type === 'required' ? required : null}
                  {errors.phoneNumber?.type === 'pattern'
                    ? 'Numero de teléfono invalido'
                    : null}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-light border-0">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="text-danger"
                      ></FontAwesomeIcon>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('email', { required: true })}
                    placeholder="Correo"
                    isInvalid={errors?.email !== undefined}
                    type="email"
                    className="bg-light border-0"
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.email ? required : null}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div>
            <div className="py-2">
              <h6 className="creditline-title title-bryant">
                Datos de la solicitud{' '}
              </h6>
            </div>
            <Row>
              <Col lg={8} xs={12}>
                <Form.Group>
                  <Form.Label>Cantidad</Form.Label>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="bg-light border-0 text-danger title-bryant">
                        {site === 'credex' ? '$' : 'Q'}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      {...register('amount', { required: true, min: 1 })}
                      name="amount"
                      type="number"
                      defaultValue={props.amount}
                      placeholder="Cantidad a solicitar"
                      isInvalid={errors?.amount !== undefined}
                      className="bg-light border-0"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.amount?.type === 'min'
                        ? 'La cantidad es invalida'
                        : null}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} xs={12}>
                <Form.Group>
                  <Form.Label>Cuotas</Form.Label>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="bg-light border-0">
                        <FontAwesomeIcon
                          icon={faHandHoldingUsd}
                          className="text-danger"
                        ></FontAwesomeIcon>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      className="creditline-select bg-light border-0"
                      {...register('term')}
                      as="select"
                      custom
                      placeholder="Cuotas"
                      defaultValue={props.term}
                    >
                      <option value="3">3</option>
                      <option value="6">6</option>
                      <option value="9">9</option>
                      <option value="12">12</option>
                      <option value="18">18</option>
                      <option value="24">24</option>
                      <option value="36">36</option>
                      <option value="48">48</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Col md={{ span: 2, offset: 10 }} xs={12}>
            <Button
              type="submit"
              variant="danger"
              className="btn-block title-bryant d-flex justify-content-center"
              disabled={isSending}
            >
              <span>
                {isSending ? (
                  <Spinner
                    as="span"
                    size="sm"
                    animation="border"
                    className="mr-4"
                  />
                ) : null}
              </span>
              <span>Enviar</span>
            </Button>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default CreditLineForm;
