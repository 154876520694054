import { Country } from 'domain/constances/country';
import News from 'domain/models/News';

export const FeaturedNewsData: News = {
  id: 1,
  country: Country.NI,
  image:
    'http://assets.cdnelnuevodiario.com/cache/31/f0/31f00228fa2d92eac85b3253022012bd.jpg',
  title: 'EE.UU. y Japón avanzan en acuerdo comercial',
  dateCreated: '03-05-2020',
  userCreator: 'credex',
  content:
    'Vivamus suscipit tortor eget felis porttitor volutpat. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur aliquet quam id dui posuere blandit. Donec rutrum congue leo eget malesuada. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.',
  url: '/',
};

export default FeaturedNewsData;

export const NewsData: News[] = [
  {
    id: 1,
    country: Country.NI,
    image:
      'http://assets.cdnelnuevodiario.com/cache/31/f0/31f00228fa2d92eac85b3253022012bd.jpg',
    title: 'Text Japón avanzan en acuerdo comercial',
    dateCreated: '03-05-2020',
    userCreator: 'credex',
    content: 'Vivamus suscipit tortor eget felis porttitor volutpat.',
    url: '/',
    tags: ['tag1', 'tag2', 'tag3'],
  },
  {
    id: 2,
    country: Country.NI,
    image:
      'http://assets.cdnelnuevodiario.com/cache/31/f0/31f00228fa2d92eac85b3253022012bd.jpg',
    title: 'EE.UU. y Japón avanzan en acuerdo comercial',
    dateCreated: '03-05-2020',
    userCreator: 'credex',
    content: 'Vivamus suscipit tortor eget felis porttitor volutpat.',
    url: '/',
  },
  {
    id: 3,
    country: Country.NI,
    image:
      'http://assets.cdnelnuevodiario.com/cache/31/f0/31f00228fa2d92eac85b3253022012bd.jpg',
    title: 'EE.UU. y Japón avanzan en acuerdo comercial',
    dateCreated: '03-05-2020',
    userCreator: 'credex',
    content: 'Vivamus suscipit tortor eget felis porttitor volutpat.',
    url: '/',
  },
  {
    id: 4,
    country: Country.NI,
    image:
      'http://assets.cdnelnuevodiario.com/cache/31/f0/31f00228fa2d92eac85b3253022012bd.jpg',
    title: 'EE.UU. y Japón avanzan en acuerdo comercial',
    dateCreated: '03-05-2020',
    userCreator: 'credex',
    content:
      'Vivamus suscipit tortor eget felis porttitor volutpat. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur aliquet quam id dui posuere blandit. Donec rutrum congue leo eget malesuada. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.',
    url: '/',
  },
];
