import React, { ReactElement } from 'react';
import './index.css';

interface TitleProps {
  title: string;
}

export const Subtitle = (props: TitleProps): ReactElement => {
  return <h2 className="title title-bryant">{props.title}</h2>;
};

export const Title = (props: TitleProps): ReactElement => {
  return (
    <>
      <h1 className="title title-bryant">{props.title}</h1>
    </>
  );
};

export const SimpleTitle = (props: TitleProps): ReactElement => (
  <h1 className="simple-title title-bryant">{props.title}</h1>
);

export default Title;
