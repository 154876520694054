import uuid from 'react-uuid';
import { UI, Alert, UIAction, ADD_ALERT, REMOVE_ALERT } from '../actions/ui';

const initialState: UI = {
  alerts: [],
};

const removeAlert = (alerts: Alert[], id: string): Alert[] => {
  return alerts.filter((alert) => alert.id !== id);
};

export const uiReducer = (state: UI = initialState, action: UIAction): UI => {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, { ...(action.payload as Alert), id: uuid() }],
      };

    case REMOVE_ALERT:
      return {
        ...state,
        alerts: removeAlert(state.alerts, action.payload as string),
      };

    default:
      return state;
  }
};
