import HttpClient, { HttpResponse } from 'core/base/HttpClient';
import Repository from 'core/base/Repository';
import { AxiosHttpClient } from 'infraestructure/HttpClient';

export abstract class HttpRepository {
  protected client: HttpClient = AxiosHttpClient.instance();
  abstract endpoint;

  // constructor() {
  //   this.client = AxiosHttpClient.createInstance(this.getService());
  // }

  protected buildUrl(resource: string): string {
    return `${this.endpoint}/${resource}`;
  }

  // protected getService(): string {
  //   return process.env.REACT_APP_CUSTOMER_SERVICE || '';
  // }
}

export abstract class BaseHttpRepository
  extends HttpRepository
  implements Repository {
  list = async (
    params: Record<string, string | number | null | boolean> = {}
  ): Promise<HttpResponse> => {
    return await this.client.get(this.endpoint, params);
  };

  get = async (id: number): Promise<HttpResponse> => {
    return await this.client.get(`${this.endpoint}/${id}`);
  };

  create = async (
    data: Record<string, string | number>
  ): Promise<HttpResponse> => {
    return await this.client.post(`${this.endpoint}`, data);
  };

  update = async (
    id: number,
    data: Record<string | number, string | number | boolean | null>
  ): Promise<HttpResponse> => {
    return await this.client.put(`${this.endpoint}`, data);
  };

  delete = async (id: number): Promise<HttpResponse> => {
    return await this.client.delete(`${this.endpoint}/${id}`);
  };
}

export default BaseHttpRepository;
