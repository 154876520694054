import React, { ReactElement, Fragment, useEffect, useState } from 'react';
import { Navbar, Nav, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { UserIcon, SignOutIcon, TimesIcon } from 'react-line-awesome';
import { useHistory } from 'react-router-dom';
import { Login } from 'presentation/components';
import AuthContext, { AuthenticationContext } from 'presentation/Context/Auth';
import Routes from 'presentation/routes';
import { AuthRepository } from 'infraestructure/repositories';
import './index.css';
import Sidebar from 'react-sidebar';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const mainLogo = process.env.REACT_APP_MAIN_LOGO;
const classLogo = process.env.REACT_APP_MAIN_LOGO_CLASS;
const site = process.env.REACT_APP_SITE_TITLE || '';

export interface NavBarMenuItem {
  url: string;
  title: string;
  country: string;
}

export interface NavBarProps {
  items: NavBarMenuItem[];
}

export const ItemsMenu = (props: NavBarProps): ReactElement => {
  const [menuSite, setMenuSite] = useState<any[]>([]);

  useEffect(() => {
    if (site === 'credex') {
      setMenuSite(props.items.filter((itemMenu) => itemMenu.country === 'NI'));
    } else {
      setMenuSite(props.items.filter((itemMenu) => itemMenu.country === 'GT'));
    }
  }, []);

  return (
    <Fragment>
      {menuSite.map((itenMenu, index) => {
        return (
          <NavLink
            exact
            activeClassName="active"
            className="nav-link"
            to={itenMenu.url}
            key={index}
          >
            {itenMenu.title}
          </NavLink>
        );
      })}
    </Fragment>
  );
};

export const NavBar = (props: NavBarProps): ReactElement => {
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
  const [showLogin, setShowLogin] = React.useState<boolean>(false);
  const history = useHistory();
  const context = React.useContext<AuthenticationContext>(AuthContext);
  const alert = withReactContent(Swal);

  const handleShowLogin = () => {
    setShowLogin(true);
  };

  const closeSesionMobile = () => {
    handleLoutMobile();
  };

  const closeSesion = () => {
    alert
      .fire({
        title: '¿Esta seguro de cerrar sesión?',
        text: 'Al cerrar sesión no tendra acceso a los datos de su línea',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si estoy seguro',
        showCloseButton: true,
        confirmButtonColor: '#e51b26',
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleLogOut();
        }
      });
  };

  const handleLogOut = () => {
    const repository = new AuthRepository();
    repository.removeToken();
    context.setIsLogged(false);
    history.push(Routes.root);
  };

  const handleLoutMobile = () => {
    handleLogOut();
    handleSidebarOpen();
  };

  const handleShowLoginMobile = () => {
    handleShowLogin();
    handleSidebarOpen();
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <Navbar
        expand="lg"
        fixed={'top'}
        className="shadow-sm main-nav bg-white navbar-elevaion"
        id="mainNav"
      >
        <Navbar.Brand className="ml-4">
          <Link to="/">
            <img src={mainLogo} className={classLogo} height="auto" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle onClick={handleSidebarOpen}></Navbar.Toggle>
        <Nav className="ml-auto mr-5 nav-menu">
          <ItemsMenu {...props} />
          <Button
            variant="danger"
            className="btn-login-mobile"
            onClick={context.isLogged ? closeSesion : handleShowLogin}
          >
            {context.isLogged ? (
              <SignOutIcon className="icon-login-btn la-lg" />
            ) : (
              <UserIcon className="icon-login-btn la-lg" />
            )}
          </Button>
        </Nav>

        <Button
          variant="danger"
          className="btn-login"
          onClick={context.isLogged ? closeSesion : handleShowLogin}
        >
          {context.isLogged ? (
            <SignOutIcon className="icon-login-btn la-lg" />
          ) : (
            <UserIcon className="icon-login-btn la-lg" />
          )}
        </Button>
      </Navbar>
      <Login show={showLogin} handleClose={() => setShowLogin(false)} />
      {/* Sidenav version movil */}
      <Sidebar
        sidebar={
          <div className="content-sidebar">
            <Container>
              <Row>
                <Col xs={12}>
                  <Button
                    variant="light"
                    className="float-left"
                    onClick={handleSidebarOpen}
                  >
                    <TimesIcon />
                  </Button>
                </Col>
                <Col xs={12} className="p-3">
                  <ItemsMenu {...props} />
                </Col>
                <Col xs={12} className="login-mobile-buttom">
                  <Button
                    variant="danger"
                    className="btn-block"
                    onClick={
                      context.isLogged
                        ? closeSesionMobile
                        : handleShowLoginMobile
                    }
                  >
                    {context.isLogged ? (
                      <Fragment>
                        <SignOutIcon className="icon-login-btn la-lg" />
                        <span>Cerrar sesión</span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <UserIcon className="icon-login-btn la-lg" />
                        <span>Iniciar sesión</span>
                      </Fragment>
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        }
        open={sidebarOpen}
        sidebarId={'mobile-menu'}
        styles={{ sidebar: { background: 'white' } }}
        pullRight={true}
        touch={false}
      >
        <></>
      </Sidebar>
    </>
  );
};
